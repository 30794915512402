require("./src/styles/global.css");
const Sentry = require("@sentry/react");

exports.onClientEntry = () => {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    enabled: process.env.GATSBY_SENTRY_ENABLED === "yes",
    release: process.env.GATSBY_SENTRY_RELEASE,
    environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
  });
};
