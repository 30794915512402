// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-22-2-tsx": () => import("./../../../src/pages/22-2.tsx" /* webpackChunkName: "component---src-pages-22-2-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-tsx": () => import("./../../../src/pages/5.tsx" /* webpackChunkName: "component---src-pages-5-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-dansaanbod-tsx": () => import("./../../../src/pages/dansaanbod.tsx" /* webpackChunkName: "component---src-pages-dansaanbod-tsx" */),
  "component---src-pages-danspartner-tsx": () => import("./../../../src/pages/danspartner.tsx" /* webpackChunkName: "component---src-pages-danspartner-tsx" */),
  "component---src-pages-disco-boogie-swing-tsx": () => import("./../../../src/pages/disco-boogie-swing.tsx" /* webpackChunkName: "component---src-pages-disco-boogie-swing-tsx" */),
  "component---src-pages-galerij-tsx": () => import("./../../../src/pages/galerij.tsx" /* webpackChunkName: "component---src-pages-galerij-tsx" */),
  "component---src-pages-gratisproeflessen-tsx": () => import("./../../../src/pages/gratisproeflessen.tsx" /* webpackChunkName: "component---src-pages-gratisproeflessen-tsx" */),
  "component---src-pages-huishoudelijk-reglement-tsx": () => import("./../../../src/pages/huishoudelijk-reglement.tsx" /* webpackChunkName: "component---src-pages-huishoudelijk-reglement-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lesgevers-tsx": () => import("./../../../src/pages/lesgevers.tsx" /* webpackChunkName: "component---src-pages-lesgevers-tsx" */),
  "component---src-pages-ligging-tsx": () => import("./../../../src/pages/ligging.tsx" /* webpackChunkName: "component---src-pages-ligging-tsx" */),
  "component---src-pages-nightclub-two-step-tsx": () => import("./../../../src/pages/nightclub-two-step.tsx" /* webpackChunkName: "component---src-pages-nightclub-two-step-tsx" */),
  "component---src-pages-opendeurdag-tsx": () => import("./../../../src/pages/opendeurdag.tsx" /* webpackChunkName: "component---src-pages-opendeurdag-tsx" */),
  "component---src-pages-openingsdans-tsx": () => import("./../../../src/pages/openingsdans.tsx" /* webpackChunkName: "component---src-pages-openingsdans-tsx" */),
  "component---src-pages-planning-dance-evening-tsx": () => import("./../../../src/pages/planning-dance-evening.tsx" /* webpackChunkName: "component---src-pages-planning-dance-evening-tsx" */),
  "component---src-pages-privelessen-tsx": () => import("./../../../src/pages/privelessen.tsx" /* webpackChunkName: "component---src-pages-privelessen-tsx" */),
  "component---src-pages-registratie-cursussen-tsx": () => import("./../../../src/pages/registratie-cursussen.tsx" /* webpackChunkName: "component---src-pages-registratie-cursussen-tsx" */),
  "component---src-pages-registratie-drink-tsx": () => import("./../../../src/pages/registratie-drink.tsx" /* webpackChunkName: "component---src-pages-registratie-drink-tsx" */),
  "component---src-pages-registratie-oefenavond-tsx": () => import("./../../../src/pages/registratie-oefenavond.tsx" /* webpackChunkName: "component---src-pages-registratie-oefenavond-tsx" */),
  "component---src-pages-solodans-tsx": () => import("./../../../src/pages/solodans.tsx" /* webpackChunkName: "component---src-pages-solodans-tsx" */),
  "component---src-pages-start-to-dance-tsx": () => import("./../../../src/pages/start-to-dance.tsx" /* webpackChunkName: "component---src-pages-start-to-dance-tsx" */),
  "component---src-pages-uurrooster-tsx": () => import("./../../../src/pages/uurrooster.tsx" /* webpackChunkName: "component---src-pages-uurrooster-tsx" */)
}

